.button-underline {
  @apply text-24 leading-normal text-neutral-100 relative;
}
.button-underline:after {
  content: "";
  width: 8px;
  height: 1px;
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  margin-left: 0;
  transition: 0.4s ease;
  transform-origin: left;

  @apply bg-tertiary-400;
}
.button-underline:hover:after,
.button-underline.hover:after {
  width: 48px;
  margin-left: calc(50% - 24px);
}
