input:checked + div img {
  @apply block;
}

.checkbox + label {
  @apply before:content-[''] before:w-[13px] before:h-[13px] before:border-[1px] before:border-primary-600 before:bg-neutral-100 before:absolute before:left-16 before:top-20;
}

.checkbox + label:after {
  content: "";
  background: url("../../../images/ic-check.png");
  background-repeat: no-repeat;
  background-size: 7px;
  @apply w-[7px] h-[5px] absolute left-[19px] top-[24px] hidden;
}

.checkbox:checked + label:after {
  @apply block;
}
