.navbar-mobile {
  display: flex;
}
.navbar-mobile li {
  @apply text-16 leading-[27px] tracking-0.03 text-primary-500 transition-all duration-300 relative px-[20px];
}
.navbar-mobile li.active {
  @apply text-tertiary-500;
}
.navbar-mobile li:first-child {
  @apply pl-[10px];
}
.navbar-mobile li::after {
  content: "";
  width: 1px;
  height: 8px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  @apply bg-primary-600;
}
.navbar-mobile li:last-child::after {
  display: none;
}
.wrapper-navbar-mobile {
  height: calc(100vh - 68px);
  opacity: 1;
  visibility: visible;
  transition: 0.3s ease;
}
.wrapper-navbar-mobile.collapse {
  height: 0;
  opacity: 0;
  visibility: hidden;
}

#nav-icon3 span:nth-child(1),
#nav-icon4 span:nth-child(1) {
  top: -2px;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3),
#nav-icon4 span:nth-child(2),
#nav-icon4 span:nth-child(3) {
  top: 6px;
}

#nav-icon3.open span:nth-child(1),
#nav-icon4.open span:nth-child(1) {
  top: 6px;
  width: 0%;
  left: 50%;
}

#nav-icon3 span:nth-child(4),
#nav-icon4 span:nth-child(4) {
  top: 14px;
}

#nav-icon3.open span:nth-child(2),
#nav-icon4.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3),
#nav-icon4.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4),
#nav-icon4.open span:nth-child(4) {
  top: 6px;
  width: 0%;
  left: 50%;
}

#nav-icon3,
#nav-icon4 {
  width: 19px;
  height: 15px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon3 span,
#nav-icon4 span {
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  border-radius: 30px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;

  @apply bg-primary-600;
}

#nav-icon4 {
  width: 27px;
}
#nav-icon4 span {
  @apply bg-primary-900;
}
#nav-icon4.open span {
  @apply bg-primary-500;
}

.navbar {
  transition: 0.3s ease;
  transform: translateX(100%);
}

.navbar.open {
  transform: translateX(0);
}

.container-navbar {
  @apply mt-[-100px];
}
.vertical-gap-menu {
  @apply gap-y-[40px];
}
.vertical-gap-menu-socmed {
  @apply gap-y-[24px];
}

@media only screen and (max-height: 800px) and (orientation: landscape) {
  .container-navbar {
    margin-top: -50px;
  }
  .vertical-gap-menu {
    row-gap: 20px;
  }
  .vertical-gap-menu-socmed {
    row-gap: 16px;
  }
}
@media only screen and (max-height: 640px) and (orientation: landscape) {
  .container-navbar {
    margin-top: 0;
  }
}
