@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Taviraj";
  src: url("./fonts/Taviraj/Taviraj-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Taviraj";
  src: url("./fonts/Taviraj/Taviraj-Medium.ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Taviraj";
  src: url("./fonts/Taviraj/Taviraj-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Quicksand";
  src: url("./fonts/Quicksand/Quicksand-Light.ttf");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Quicksand";
  src: url("./fonts/Quicksand/Quicksand-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Quicksand";
  src: url("./fonts/Quicksand/Quicksand-Medium.ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Quicksand";
  src: url("./fonts/Quicksand/Quicksand-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

body,
html {
  margin: 0;
  font-family: "Quicksand";
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1e1e1e;
  scroll-behavior: smooth;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #280d02 inset;
  -webkit-text-fill-color: #fff !important;
}

input:-webkit-autofill.disabled {
  -webkit-box-shadow: 0 0 0px 1000px #17181a inset;
}
