.modal {
  @apply w-full h-full fixed top-0 left-0 flex justify-center items-center overflow-x-hidden overflow-x-hidden z-[99] transition duration-300 opacity-0 pointer-events-none;
}

.modal-enter-done {
  @apply opacity-100 pointer-events-auto;
}

.modal-exit {
  @apply opacity-0;
}

.modal-overlay {
  @apply fixed w-full h-full bg-[#00000099] z-0;
}

.modal-container {
  @apply w-full max-w-[848px] p-[16px] relative z-[1];
}

.modal-inner-container {
  @apply bg-primary-300 p-[72px] relative;
}

.modal-close {
  @apply absolute top-[32px] right-[32px];
}

.modal-close svg {
  @apply opacity-90 transition duration-300;
}

.modal-close:hover svg {
  @apply opacity-100;
}

.modal-content {
  @apply block w-full;
}
