.main-slider .slick-dots,
.main-slider-desktop .slick-dots {
  bottom: -30px;
  text-align: left;
  padding: 0 11px;
}
.main-slider .slick-dots li,
.main-slider-desktop .slick-dots li {
  width: 10px;
  height: 10px;
}
.main-slider .slick-dots li button,
.main-slider-desktop .slick-dots li button {
  width: inherit;
  height: inherit;
  border-radius: 0;
  padding: 0;
  border: 1px solid #e8eaed;
}

.main-slider .slick-dots li button:before,
.main-slider-desktop .slick-dots li button:before {
  width: inherit;
  height: inherit;
  color: transparent;
}

.main-slider .slick-dots li.slick-active button:before,
.main-slider-desktop .slick-dots li.slick-active button:before {
  opacity: 1;
  background-color: #e8eaed;
}

.main-slider .slick-dots {
  display: inline-block !important;
  width: auto;
}

.main-slider-desktop .slick-dots {
  bottom: -48px;
  left: 48px;
}

.main-slider-desktop .slick-next:before,
.main-slider-desktop .slick-prev:before {
  content: "";
  width: inherit;
  height: inherit;
}

.main-slider-desktop .slick-next.slick-disabled,
.main-slider-desktop .slick-prev.slick-disabled {
  display: none !important;
}

.custom-dots-container {
  @apply flex items-center gap-[4px];
}

.custom-dots-container .custom-dot {
  @apply w-[10px] h-[10px] border-[1px] border-neutral-100 transition duration-300;
}

.custom-dots-container .custom-dot.active {
  @apply bg-neutral-100;
}

.image-talent {
  transition: 0.6s ease 0.4s;
  transform: translateX(30px);
  opacity: 0;
}
.image-talent-pattern {
  transition: 0.6s ease 0.25s;
  opacity: 0;
}
.info-talent-title {
  transition: 0.6s ease 0.5s;
  transform: translateY(20px);
  opacity: 0;
}
.info-talent-category {
  transition: 0.6s ease 0.6s;
  transform: translateY(20px);
  opacity: 0;
}
.info-talent-socmed {
  transition: 0.6s ease 0.5s;
  transform: translateY(20px);
  opacity: 0;
}
.info-talent-vip {
  transition: 0.6s ease 0.7s;
  transform: translateY(20px);
  opacity: 0;
}

.slick-active .image-talent {
  transform: translateX(0);
  opacity: 1;
}

.slick-active .image-talent-pattern {
  opacity: 1;
}

.slick-active .info-talent-title,
.slick-active .info-talent-category,
.slick-active .info-talent-socmed,
.slick-active .info-talent-vip {
  transform: translateY(0);
  opacity: 1;
}

.slick-track,
.slick-list {
  -webkit-perspective: 2000;
  -webkit-backface-visibility: hidden;
}
