.service-thumb-overlay {
  background: linear-gradient(
      0deg,
      rgba(77, 39, 4, 0.05),
      rgba(77, 39, 4, 0.05)
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}

.ico-chevron-up {
  background-repeat: no-repeat;
  background-position: center;
  width: 22px;
  height: 21px;
  display: inline-block;
}

.service-overlay-content {
  bottom: -100%;
  transition: 0.4s ease;
}
.service-overlay-content.open {
  bottom: 0;
}
