.portfolio-slider .slick-dots {
  @apply bottom-20 z-[3];
  text-align: right;
  padding: 0 11px;
  width: auto;
  margin: 0 0 0 auto;
  right: 0;
}

.portfolio-slider .slick-dots li {
  width: 10px;
  height: 10px;
  margin: 0 2px;
}

.portfolio-slider .slick-dots li button {
  width: inherit;
  height: inherit;
  border-radius: 0;
  padding: 0;
  border: 1px solid #e8eaed;
}

.portfolio-slider .slick-dots li button:before {
  width: inherit;
  height: inherit;
  color: transparent;
}

.portfolio-slider .slick-dots li.slick-active button:before {
  opacity: 1;
  background-color: #e8eaed;
}
