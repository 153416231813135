@media only screen and (min-width: 1024px) {
  .form-choice-link .form-choice-link-desc span {
    transition: 0.4s ease;
    margin-left: -15px;
  }
  .form-choice-link:hover .form-choice-link-desc span {
    margin-left: 0;
  }

  .form-choice-link:after {
    content: "";
    width: 1px;
    height: 200px;
    background-color: #87562e;
    right: -64px;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
  }

  .form-choice-link-wrapper:last-child .form-choice-link::after {
    display: none;
  }
}
