.overlay-content-slider-mobile .header-close,
.overlay-content-slider-mobile .body-drawer {
  @apply before:content-[''] before:w-full before:h-[3px] before:absolute before:top-[-3px] before:left-0 before:bg-tertiary-400;
}
.overlay-content-slider-mobile.open .header-close {
  @apply before:hidden;
}

.overlay-content-slider-mobile.open .body-drawer {
  @apply before:block;
}
