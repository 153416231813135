.overlay-top-card-talent {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 28.65%,
    rgba(0, 0, 0, 0.7) 100%
  );
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.border-top-drawer {
  @apply before:content-[''] before:w-full before:h-[3px] before:absolute before:top-[-3px] before:left-0 before:bg-tertiary-400;
}

.talent-card .area-click:before {
  content: "";
  transition: 0.3s ease;
  @apply w-full h-[3px] bg-tertiary-500 absolute bottom-0 left-0 z-10 opacity-100;
}

.talent-card.open .area-click:before {
  @apply opacity-0;
}

@media only screen and (min-width: 1024px) {
  .talent-card .image-thumb {
    transition: 0.4s ease;
    transform: scale(1);
  }

  .talent-card:hover .image-thumb {
    transform: scale(1.1);
  }
}
